import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Link from '../../components/Common/Link'
import { Button } from '../../components/Common/Button'
import { toLink } from '../../utils'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const WebinarUniformatRu: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="Классификатор элементов 3D-модели"
        description="Uniformat является хорошим примером как можно сделать классификатор строительной отрасли. На его основе коммерческие департаменты и департаменты планирования создают структуры разбивки работ (WBS) внутри своих компаний по всему миру."
      />
      <NewsSection
        title="Применение Uniformat в SIGNAL, Tangl, IYNO для подсчета объемов работ"
        dateData={{ date: '24.01.2024' }}
        rayContent
      >
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'my-4')}
            src="../../assets/images/news/webinar-uniformat-ru/image-1.png"
            alt="Применение Uniformat в SIGNAL, Tangl, IYNO для подсчета объемов работ"
            title="Применение Uniformat в SIGNAL, Tangl, IYNO для подсчета объемов работ"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Российские разработчики: SIGNAL (
          <GatsbyLink to={getPath(PathEnum.Home)} className="has-text-primary">
            sgnl.pro
          </GatsbyLink>
          ), Tangl (
          <Link
            href="https://tangl.cloud"
            rel="nofollow noreferrer"
            target="_blank"
          >
            tangl.cloud
          </Link>
          ) и IYNO (
          <Link
            href="https://iyno.ru"
            rel="nofollow noreferrer"
            target="_blank"
          >
            iyno.ru
          </Link>
          ) перевели классификатор Uniformat II 2015 года (ASTM E1557) и
          выложили его в открытый доступ, скачать который можно по ссылке{' '}
          <GatsbyLink
            to={getPath(PathEnum.Uniformat)}
            className="has-text-primary"
          >
            https://sgnl.pro/uniformat/
          </GatsbyLink>
        </p>
        <p className={s.text}>
          Приглашаем вас принять участие в онлайн вебинаре, где представители
          компаний поделятся кейсами применения классификатора при
          проектировании BIM-моделей.
        </p>
        <p className={s.text}>
          <strong>{`Дата: 01 Февраля 2024\nВремя: 11:00 МСК`}</strong>
        </p>
        <div className="w-100 flex justify-center">
          <Button
            size="large"
            className="my-4"
            onClick={() =>
              toLink('https://www.youtube.com/watch?v=ISFUqe7XtWE')
            }
          >
            Смотреть запись
          </Button>
        </div>
        <div className="flex flex-col items-center">
          <h2 className={s.textTitle}>Спикеры</h2>
          <div className={cn(s.columnWrapper, 'w-100 mb-4')}>
            <div className="flex flex-col items-center w-100">
              <StaticImage
                width={160}
                imgStyle={{ mixBlendMode: 'multiply' }}
                src="../../assets/images/news/webinar-uniformat-ru/Popov.png"
                alt="Александр Попов"
                title="Александр Попов"
                placeholder="none"
              />
              <p className={cn(s.text, 'm-0')}>Александр Попов</p>
              <p className={cn(s.text, 'has-text-centered m-0')}>
                {`Технический директор\nSIGNAL`}
              </p>
            </div>
            <div className="flex flex-col items-center w-100">
              <StaticImage
                width={160}
                imgStyle={{ mixBlendMode: 'multiply' }}
                src="../../assets/images/news/webinar-uniformat-ru/Lobanov.png"
                alt="Алексей Лобанов"
                title="Алексей Лобанов"
                placeholder="none"
              />
              <p className={cn(s.text, 'm-0')}>Алексей Лобанов</p>
              <p className={cn(s.text, 'has-text-centered m-0')}>
                {`Технический директор\nTangl`}
              </p>
            </div>
            <div className="flex flex-col items-center w-100">
              <StaticImage
                width={160}
                imgStyle={{ mixBlendMode: 'multiply' }}
                src="../../assets/images/news/webinar-uniformat-ru/Mitin.png"
                alt="Роман Митин"
                title="Роман Митин"
                placeholder="none"
              />
              <p className={cn(s.text, 'm-0')}>Роман Митин</p>
              <p className={cn(s.text, 'has-text-centered m-0')}>
                {`Директор по продукту\nIYNO`}
              </p>
            </div>
          </div>
        </div>

        <p className={s.text}>
          "Мы это сделали чтобы у компаний появился хоть какой-нибудь адекватный
          классификатор. Хотим попросить проектные и строительные компании
          заполнять хотя бы этот классификатор в своих моделях, если нет
          собственного.
        </p>
        <p className={s.text}>
          Также, мы рекомендуем компаниям Заказчикам, которые заказывают BIM
          своим проектировщикам, но не имеют своего внутреннего классификатора
          элементов, включить следующий пункт в Информационные требования
          заказчика или техническое задание на проектирование:
        </p>
        <p className={s.text}>
          “Все элементы BIM-модели, отображаемые на 3D виде или учитываемые при
          составлении ведомостей, спецификаций, смет, должны быть
          классифицированы по классификатору UNIFORMAT 2015 RU”
        </p>
        <p className={s.text}>
          <GatsbyLink
            to={getPath(PathEnum.Uniformat)}
            className="has-text-primary"
          >
            Скачать классификатор UNIFORMAT 2015 RU
          </GatsbyLink>
        </p>
        <p className={s.text}>
          Вебинар будет интересен всем участникам строительной отрасли
          использующих подходы BIM моделирования в своей работе.
        </p>
        <p className={s.text}>Будем рады видеть вас на вебинаре!</p>
      </NewsSection>
    </>
  )
}

export default WebinarUniformatRu
